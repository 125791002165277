import "./auth-login-button.css";

interface Props {
  onClick?: () => void;
  style?: React.CSSProperties;
  label: string;
  logo: "Google" | "Microsoft";
}

const MICROSOFT_LOGO = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 256 256"
  >
    <path fill="#f1511b" d="M121.666 121.666H0V0h121.666z" />
    <path fill="#80cc28" d="M256 121.666H134.335V0H256z" />
    <path fill="#00adef" d="M121.663 256.002H0V134.336h121.663z" />
    <path fill="#fbbc09" d="M256 256.002H134.335V134.336H256z" />
  </svg>
);

const GOOGLE_LOGO = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    style={{ display: "block" }}
  >
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    ></path>
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    ></path>
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    ></path>
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    ></path>
    <path fill="none" d="M0 0h48v48H0z"></path>
  </svg>
);

export function AuthLoginButton(props: Props) {
  const logo = props.logo === "Google" ? GOOGLE_LOGO : MICROSOFT_LOGO;
  return (
    <button
      className="auth-material-button"
      onClick={props.onClick}
      style={props.style}
    >
      <div className="auth-material-button-state"></div>
      <div className="auth-material-button-content-wrapper">
        <div className="auth-material-button-icon">{logo}</div>
        <span className="auth-material-button-contents">{props.label}</span>
        <span style={{ display: "none" }}>{props.label}</span>
      </div>
    </button>
  );
}
