import { Alert, Button, FormControlLabel, Snackbar, Switch, Typography } from "@mui/material"
import { Col } from "../../../components/common/Col"
import { ResponsiveCol } from "../../../components/common/ResponsiveCol"
import { Card } from "../../../components/common/elements/Card"
import { Label } from "../../../components/common/Label"
import { Row } from "../../../components/common/Row"
import { ValidatedTextField } from "../../../components/common/elements/ValidatedTextField"
import { FormEvent, useEffect, useState } from "react"
import { getAnalytics, logEvent } from "firebase/analytics"
import { LoadingButton, LoadingButtonState } from "../../../components/common/elements/LoadingButton"
import { AdminApiClient } from "../../../models/apiClients/adminApiClient"
import { LoadingScreen } from "../../Loading/LoadingScreen"
import { UnexpectedErrorScreen } from "../../Error/UnexpectedErrorScreen"

export const SamlSsoSettings = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadError, setLoadError] = useState<boolean>(false);
    const [openSaveSnackbar, setOpenSaveSnackbar] = useState<boolean>(false);

    const [enabled, setEnabled] = useState<boolean>(false);
    const [idpEntityId, setIdpEntityId] = useState<string | undefined>();
    const [ssoUrl, setSsoUrl] = useState<string | undefined>();
    const [x509Certs, setX509Certs] = useState<string | undefined>();
    const [idpEntityIdError, setIdpEntityIdError] = useState<boolean>(false);
    const [ssoUrlError, setSsoUrlError] = useState<boolean>(false);
    const [x509CertsError, setX509CertsError] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [buttonState, setButtonState] =
        useState<LoadingButtonState>("available");

    const adminApiClient = AdminApiClient.getInstance();
    useEffect(() => {
        adminApiClient.getSamlConfig().then((res) => {
            setEnabled(res.enabled);
            setIdpEntityId(res.samlConfig?.idpEntityId);
            setSsoUrl(res.samlConfig?.ssoUrl);
            setX509Certs(res.samlConfig?.x509Certificates);
            setLoaded(true);
        }).catch((err) => {
            console.error(err);
            setLoadError(true);
        });
    }, []);

    const hasError = (): boolean => {
        console.log(idpEntityIdError, ssoUrlError, x509CertsError);
        return idpEntityIdError || ssoUrlError || x509CertsError;
    };

    const onChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabled(event.target.checked);
    }
    const onChangeIdpEntityId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIdpEntityId(event.target.value);
    };
    const onChangeSsoUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSsoUrl(event.target.value);
    };
    const onChangeX509Certs = (event: React.ChangeEvent<HTMLInputElement>) => {
        setX509Certs(event.target.value);
    };

    const analytics = getAnalytics();
    const onSubmit = async (e: FormEvent<any>) => {
        e.preventDefault();
        if (!idpEntityId || !ssoUrl || !x509Certs) {
            return;
        }
        try {
            logEvent(analytics, "updated_saml_sso_settings");
            setErrorMessage(undefined);
            setButtonState("loading");
            await adminApiClient.updateSamlConfig(enabled, idpEntityId, ssoUrl, x509Certs);
            setOpenSaveSnackbar(true);
            setButtonState("available");
        } catch (err) {
            console.error(err);
            setErrorMessage("設定の保存に失敗しました。");
            setButtonState("available");
        }
    }
    if (!loaded && !loadError) {
        return <LoadingScreen />;
    }
    if (loadError) {
        return <UnexpectedErrorScreen />;
    }

    return (
        <Col
            sx={{
                flex: 1,
                height: "100%",
                alignContent: "center",
                justifyContent: "start",
            }}
        >
            <ResponsiveCol>
                <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
                    SAML SSO 設定
                </Typography>
                <Card withBorder filled>
                    <form onSubmit={onSubmit}>
                        <Col sx={{ marginBottom: "24px" }}>
                            <Label>SSOを有効にする</Label>
                            <Switch
                                checked={enabled}
                                onChange={onChangeEnabled}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Col>
                        <Col sx={{ marginBottom: "24px" }}>
                            <Label>SSO Redirect URL</Label>
                            <Row>
                                <ValidatedTextField
                                    type="text"
                                    variant={"outlined"}
                                    value={ssoUrl}
                                    inputProps={{
                                        required: true,
                                    }}
                                    watchError={setSsoUrlError}
                                    onChange={onChangeSsoUrl}
                                    sx={{
                                        width: "600px",
                                    }}
                                />
                            </Row>
                            <Row sx={{ fontSize: "12px", color: "gray" }}>
                                SSO ログイン時に遷移するURLを入力してください。
                            </Row>
                        </Col>
                        <Col sx={{ marginBottom: "24px" }}>
                            <Label>Issuer</Label>
                            <Row>
                                <ValidatedTextField
                                    type="text"
                                    variant={"outlined"}
                                    value={idpEntityId}
                                    inputProps={{
                                        required: true,
                                    }}
                                    watchError={setIdpEntityIdError}
                                    onChange={onChangeIdpEntityId}
                                    sx={{
                                        width: "600px",
                                    }}
                                />
                            </Row>
                            <Row sx={{ fontSize: "12px", color: "gray" }}>
                                一般的にはSAMLプロバイダが提供するURLを入力してください。Entity ID とも呼ばれます。
                            </Row>
                        </Col>
                        <Col sx={{ marginBottom: "24px" }}>
                            <Label>X.509 証明書</Label>
                            <Row>
                                <ValidatedTextField
                                    type="text"
                                    variant={"outlined"}
                                    value={x509Certs}
                                    multiline
                                    minRows={8}
                                    inputProps={{
                                        required: true,
                                    }}
                                    watchError={setX509CertsError}
                                    onChange={onChangeX509Certs}
                                    sx={{
                                        width: "600px",
                                    }}
                                />
                            </Row>
                            <Row sx={{ fontSize: "12px", color: "gray" }}>
                                先頭は「-----BEGIN CERTIFICATE-----」、末尾は「-----END CERTIFICATE-----」としてください。
                            </Row>
                        </Col>
                        <Col sx={{ alignItems: "start" }}>
                            {!!errorMessage ? (
                                <Alert severity="error" sx={{ marginBottom: "16px" }}>
                                    {errorMessage}
                                </Alert>
                            ) : (
                                <div />
                            )}
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                onClick={onSubmit}
                                buttonState={buttonState}
                                disabled={hasError()}
                            >
                                保存
                            </LoadingButton>
                        </Col>
                    </form>
                </Card>
            </ResponsiveCol>
            <Snackbar
                open={openSaveSnackbar}
                autoHideDuration={6000}
                onClose={() => {
                    setOpenSaveSnackbar(false);
                }}
            >
                <Alert severity="success" variant={"filled"} sx={{ width: "100%" }}>
                    設定変更を保存しました。
                </Alert>
            </Snackbar>
        </Col>
    )
}