import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { Label } from "../../components/common/Label";
import { FileUpload } from "./FileUpload/FileUpload";
import { useEffect, useState } from "react";
import { ConfirmationDialog } from "../../components/common/elements/ConfirmationDialog";
import { formatFileSize } from "../../utils/formatFileSize";
import { FileMetaDao } from "@chatforce/common/src/dao/firestoreDao";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { StorageService } from "../../models/services/storageService";
import { useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../AppStates";
import { DateTime } from "luxon";
import { useCustomPagination } from "../../models/hooks/hooks";

const tableCellStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const FileManagement = () => {
  const userClient = UserApiClient.getInstance();
  const storageService = StorageService.getInstance();

  const tenantUserState = useRecoilValueLoadable(currentTenantUserQuery);
  const tenantId = tenantUserState.contents.tenantId;

  const [fileMetas, setFileMetas] = useState<FileMetaDao[]>([]);
  const [deletingFileMetaId, setDeletingFileMetaId] = useState<string | null>(
    null,
  );
  const { page, pageCount, paginatedData, showPagination, handlePageChange } =
    useCustomPagination({ items: fileMetas });

  const fileInfo = fileMetas.map((item) => ({
    id: item.id,
    fileName: item.fileName,
  }));

  const totalFileSize = fileMetas.reduce((acc, file) => {
    return acc + file.size;
  }, 0);

  const onClickDeleteFile = (fileMetaId: string) => {
    setDeletingFileMetaId(fileMetaId);
  };

  const handleDeleteFile = async (fileMetaId: string) => {
    const deletingFileMeta = fileMetas.find((item) => item.id === fileMetaId);
    if (deletingFileMeta === undefined) return;

    await userClient.deleteFileMeta({ id: fileMetaId });
    await storageService.deleteFile(
      tenantId,
      `uploads/${deletingFileMeta.fileName}`,
    );

    setFileMetas((prev) => prev.filter((item) => item.id !== fileMetaId));
    setDeletingFileMetaId(null);
  };

  useEffect(() => {
    userClient.getFileMetas().then((res) => {
      const metaOfFiles: FileMetaDao[] = res.fileMetas.map((item) => ({
        id: item.id,
        createdAt: item.createdAt,
        fileName: item.fileName,
        size: item.size,
        type: item.type,
      }));
      setFileMetas(metaOfFiles);
    });
  }, []);

  return (
    <>
      <ResponsiveCol sx={{ height: "100%" }}>
        <Box sx={{ flex: 1, width: "100%" }}>
          <Typography variant={"h1"} sx={{ marginBottom: "40px" }}>
            ファイル管理
          </Typography>

          <Label>ファイルリスト</Label>
          <TableContainer
            component={Paper}
            sx={{
              display: "flex",
              width: "100%",
              maxHeight: "calc(100vh - 450px)",
            }}
          >
            <Table sx={{ minWidth: "100%", width: "100%" }} size="small">
              <TableHead
                sx={{
                  backgroundColor: "white",
                  position: "sticky",
                  zIndex: 1,
                  top: "0",
                }}
              >
                <TableRow
                  sx={{
                    backgroundColor: "#fff",
                  }}
                >
                  <TableCell sx={{ ...tableCellStyle }}>ファイル名</TableCell>
                  <TableCell sx={{ ...tableCellStyle }}>更新日</TableCell>
                  <TableCell sx={{ ...tableCellStyle }}>サイズ</TableCell>
                  <TableCell
                    sx={{
                      ...tableCellStyle,
                      maxWidth: "100px",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((file, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        sx={{ ...tableCellStyle }}
                        component="th"
                        scope="row"
                      >
                        {file.fileName}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle }}>
                        {file.createdAt
                          ? DateTime.fromJSDate(
                              file.createdAt.toDate(),
                            ).toFormat("yyyy年MM月dd日")
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyle,
                          maxWidth: "150px",
                        }}
                      >
                        {formatFileSize(file.size)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "end", width: "100px" }}>
                        <Button
                          color={"error"}
                          variant={"contained"}
                          onClick={() => onClickDeleteFile(file.id)}
                        >
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination && (
            <Stack alignItems="flex-end" marginTop={1}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={(_, value) => handlePageChange(value)}
              />
            </Stack>
          )}
          <FileUpload
            sx={{ marginTop: "40px" }}
            setFileMetas={setFileMetas}
            uploadedFileInfo={fileInfo}
          />
        </Box>
        <Label>トータルサイズ: {formatFileSize(totalFileSize)}</Label>
      </ResponsiveCol>
      <ConfirmationDialog
        open={deletingFileMetaId !== null}
        title={"ファイルの削除"}
        body={"ファイルを削除しますか？"}
        buttonColor={"error"}
        buttonText={"削除する"}
        onConfirm={async () => {
          if (deletingFileMetaId === null) return;
          await handleDeleteFile(deletingFileMetaId);
        }}
        onClose={() => setDeletingFileMetaId(null)}
      />
    </>
  );
};
