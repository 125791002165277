import { useEffect, useState } from "react";
import { LoadingButton, LoadingButtonState } from "./elements/LoadingButton";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, getRedirectResult, SAMLAuthProvider, signInWithRedirect, UserCredential } from "firebase/auth";

export interface Props {
    tenantId: string;
    onLoginSuccess: (userCredential: UserCredential) => void
};

export const SamlSsoLoginButton = (props: Props) => {
    const pendingKey = 'pendingFbAuthRedirect';
    const defaultSamlId = "saml.tenantidp1";
    const [buttonState, setButtonState] = useState<LoadingButtonState>("available");
    const provider = new SAMLAuthProvider(defaultSamlId);
    const analytics = getAnalytics();
    const auth = getAuth();
    const onClick = async () => {
        window.sessionStorage.setItem(pendingKey, 'true');
        logEvent(analytics, "clicked_saml_sso_login_button");
        setButtonState("loading");
        await signInWithRedirect(auth, provider);
        // singInWithRedirect は ページ遷移するので、その後の処理を書いても意味がない
    };

    useEffect(() => {
        if (window.sessionStorage.getItem(pendingKey) === 'true') {
            window.sessionStorage.removeItem(pendingKey);
            setButtonState("loading");
        }
    }, []);

    useEffect(() => {
        if (props.tenantId === undefined || props.tenantId === null) {
            return;
        }
        auth.tenantId = props.tenantId;
        getRedirectResult(auth)
            .then((result) => {
                console.log("Logged in with SSO", result);
                if (result === null) {
                    console.error("SAML Logic user credential is null");
                } else {
                    props.onLoginSuccess(result);
                }
            })
            .catch((error) => {
                // Handle Errors here.
                console.dir(error);
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = SAMLAuthProvider.credentialFromError(error);
                console.error("SAML Logic error", errorCode, errorMessage, email, credential);
            });
    }, [props.tenantId]);

    return <LoadingButton
        onClick={() => {
            onClick();
        }}
        type="submit"
        variant="contained"
        size={"large"}
        sx={{ alignSelf: "center" }}
        style={{ marginTop: "16px" }}
        buttonState={buttonState}
    >
        SSO ログイン
    </LoadingButton>
}