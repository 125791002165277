import { Row } from "../Row";

export const Divider = () => {
    return (
        <Row sx={{
            alignItems: "center",
            justifyContent: "center",
            minHeight: "24px",
            width: "100%",
        }}>
            <div style={{
                flex: 1,
                height: "1px",
                backgroundColor: "#cccccc",
                margin: "0 10px",
            }} />
            <span style={{
                fontSize: "14px",
                color: "#333333",
            }}>または</span>
            <div style={{
                flex: 1,
                height: "1px",
                backgroundColor: "#ccc",
                margin: "0 10px",
            }} />
        </Row >);
}
