import { getAuth } from "firebase/auth";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import { createPromiseClient, PromiseClient } from "@connectrpc/connect";
import {
  GetSamlConfigResponse,
  GetTenantIpBlockResponse,
  InviteUserResponse,
  ListAllowedIpsResponse,
  ListAuditLogsRequest,
  ListAuditLogsResponse,
  ListTokenUsageDataByYearResponse,
  ListTokenUsageYearsRequest,
  ListTokenUsageYearsResponse,
  Role,
  UpdateSamlConfigRequest,
} from "../../buf/chatforce/admin/v1/admin_pb";
import { TenantUserRole } from "@chatforce/common/src/dao/firestoreDao";
import { AdminService } from "../../buf/chatforce/admin/v1/admin_connect";
import { AiModel } from "../../buf/chatforce/user/v1/chat_pb";

export class AdminApiClient {
  private static instance: AdminApiClient;
  adminClient: PromiseClient<typeof AdminService>;

  roleMap: { [key in TenantUserRole]: Role } = {
    superAdmin: Role.SUPER_ADMIN,
    admin: Role.ADMIN,
    user: Role.USER,
    none: Role.UNSPECIFIED,
  };

  private constructor() {
    const baseUrl = import.meta.env.VITE_API_URL;
    if (baseUrl === undefined) throw Error("VITE_API_URL is undefined");
    const interceptor = (next: any) => async (req: any) => {
      const token = await getAuth().currentUser?.getIdToken();
      if (token !== undefined) {
        req.header.set("Authorization", `Bearer ${token}`);
      }
      return next(req);
    };

    const transport = createGrpcWebTransport({
      baseUrl,
      interceptors: [interceptor],
    });
    this.adminClient = createPromiseClient(AdminService, transport);
  }

  static getInstance() {
    if (!AdminApiClient.instance) {
      AdminApiClient.instance = new AdminApiClient();
    }
    return AdminApiClient.instance;
  }

  async inviteUser(
    email: string,
    displayName: string,
    groupId: string | undefined,
    role: TenantUserRole,
    resend: boolean,
  ): Promise<string> {
    const requestRole = this.roleMap[role];
    const result: InviteUserResponse = await this.adminClient.inviteUser({
      displayName,
      email,
      groupId,
      role: requestRole,
      resend,
    });
    const invitationId = result.invitation?.id;
    if (invitationId === undefined) {
      throw Error("Invitation id is undefined");
    }
    return invitationId;
  }

  async updateTenantUser(
    userId: string,
    displayName?: string,
    groupId?: string,
    role?: TenantUserRole,
  ): Promise<void> {
    const requestRole = role !== undefined ? this.roleMap[role] : undefined;
    await this.adminClient.updateTenantUser({
      userId,
      displayName,
      groupId,
      role: requestRole,
    });
  }
  async updateInvitation(
    invitationId: string,
    displayName?: string,
    groupId?: string,
    role?: TenantUserRole,
  ): Promise<void> {
    const requestRole = role !== undefined ? this.roleMap[role] : undefined;
    await this.adminClient.updateInvitation({
      invitationId,
      displayName,
      groupId,
      role: requestRole,
    });
  }

  async deleteInvitation(invitationId: string): Promise<void> {
    await this.adminClient.deleteInvitation({
      id: invitationId,
    });
  }
  async deleteTenantUser(userId: string): Promise<void> {
    await this.adminClient.deleteTenantUser({
      id: userId,
    });
  }

  async addTenantGroup(name: string): Promise<void> {
    await this.adminClient.addTenantGroup({
      name,
    });
  }

  async updateTenantGroup(
    id: string,
    name: string,
    sortIndex?: number,
  ): Promise<void> {
    await this.adminClient.updateTenantGroup({
      id,
      name,
      sortIndex,
    });
  }

  async deleteTenantGroup(id: string): Promise<void> {
    await this.adminClient.deleteTenantGroup({
      id,
    });
  }

  async listAuditLogs(
    data: Pick<ListAuditLogsRequest, "startDate" | "endDate">,
  ): Promise<ListAuditLogsResponse> {
    return await this.adminClient.listAuditLogs(data);
  }

  async listTokenUsageYears(): Promise<ListTokenUsageYearsResponse> {
    const request = new ListTokenUsageYearsRequest();
    return await this.adminClient.listTokenUsageYears(request);
  }

  async listTokenUsageDataByYear(year: string, aiModel: AiModel): Promise<ListTokenUsageDataByYearResponse> {
    return await this.adminClient.listTokenUsageDataByYear({ year, aiModel });
  }

  async upsertAllowedIp(ip: string, id?: string): Promise<{}> {
    return await this.adminClient.upsertAllowedIp({ ip, id });
  }

  async listAllowedIps(): Promise<ListAllowedIpsResponse> {
    return await this.adminClient.listAllowedIps({});
  }

  async deleteAllowedIp(id: string): Promise<{ currentIp: string, updated: boolean }> {
    return await this.adminClient.deleteAllowedIp({ id });
  }

  async getTenantIpBlock(): Promise<GetTenantIpBlockResponse> {
    return await this.adminClient.getTenantIpBlock({});
  }

  async updateTenantIpBlock(enabledIpBlock: boolean): Promise<{ updated: boolean, currentIp: string }> {
    return await this.adminClient.updateTenantIpBlock({
      enabledIpBlock,
    });
  }

  async getSamlConfig(): Promise<GetSamlConfigResponse> {
    return await this.adminClient.getSamlConfig({});
  }

  async updateSamlConfig(enabled: boolean, idpEntityId: string, ssoUrl: string, x509Certificates: string): Promise<void> {
    await this.adminClient.updateSamlConfig({
      enabled,
      samlConfig: {
        idpEntityId,
        ssoUrl,
        x509Certificates,
      },
    });
  }
}