import { Alert, Divider, Link, Typography } from "@mui/material";
import { ComponentProps, FormEvent, useEffect, useState } from "react";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
  UserCredential,
} from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { Row } from "../../components/common/Row";
import { useTenantId } from "../../models/hooks/hooks";
import { LoadingScreen } from "../Loading/LoadingScreen";
import { NotFoundScreen } from "../Error/NotFoundScreen";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { configs } from "../../models/utils/Configs";
import { Card } from "../../components/common/elements/Card";
import { sleep } from "../../utils/sleep";
import { Col } from "../../components/common/Col";
import { AuthLoginButton } from "../../components/common/AuthLoginButton";
import { SamlSsoLoginButton } from "../../components/common/SamlSsoLoginButton";

type LinkProps = ComponentProps<typeof Link>;
const LinkText = (props: LinkProps) => {
  return (
    <Link
      {...props}
      underline="none"
      sx={{
        ...props.sx,
        fontSize: "small",
        color: "#555555",
        padding: "1px",
      }}
    >
      {props.children}
    </Link>
  );
};
export const LoginScreen = () => {
  const tenantId = useTenantId();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [passwordInputError, setPasswordInputError] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string | undefined>(undefined);
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [isAllowed, setIsAllowed] = useState<boolean | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPathName = location.state?.from?.pathname;
  const analytics = getAnalytics();
  const showDebugLogin = configs.features.guestLogin;

  useEffect(() => {
  }, [tenantId]);

  const hasError = (): boolean => {
    return emailInputError || passwordInputError;
  };
  const onLoginSuccess = async (cred: UserCredential) => {
    console.log("Login succeeded", cred.user, fromPathName);
    setErrMessage(undefined);
    logEvent(analytics, "logged_in");
    // App State に auth 状態が伝播するのを待つための  Work around
    await sleep(1000);
    if (!fromPathName) {
      navigate("/", { replace: true });
    } else {
      navigate(fromPathName, { replace: true });
    }
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    logEvent(analytics, "clicked_login_button");
    setErrMessage(undefined);
    const auth = getAuth();
    if (tenantId === undefined) {
      return;
    }
    setButtonState("loading");
    auth.tenantId = tenantId;
    if (email === "" || password === "") {
      console.error("Email or password is not set");
      return;
    }
    // TODO: Sign in しても遷移しないのを直す
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        onLoginSuccess(userCredential);
      })
      .catch((error) => {
        console.error(error);
        setErrMessage("ログインに失敗しました");
      })
      .finally(() => {
        setButtonState("available");
      });
  };

  const onClickDebugLogin = async (accountKey: string) => {
    setErrMessage(undefined);
    const auth = getAuth();
    if (tenantId === undefined || tenantId === null) {
      console.error("Tenant ID is not set");
      return;
    }
    auth.tenantId = tenantId;
    const account = {
      user: "seiji+user@coruscant.co.jp",
      user2: "test+user2@coruscant.co.jp",
      admin: "test+admin@coruscant.co.jp",
      superAdmin: "test+superadmin@coruscant.co.jp",
    }[accountKey];
    if (account === undefined) {
      console.error(`account not found for role: ${accountKey}`);
      return;
    }
    signInWithEmailAndPassword(auth, account, "111111")
      .then((userCredential) => {
        onLoginSuccess(userCredential);
      })
      .catch((_error) => {
        setErrMessage("ログインに失敗しました");
      });
  };

  const onClickGoogleLogin = async () => {
    setErrMessage(undefined);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    if (tenantId === undefined || tenantId === null) {
      console.error("Tenant ID is not set");
      return;
    }

    auth.tenantId = tenantId;
    logEvent(analytics, "clicked_google_login_button");
    signInWithRedirect(auth, provider);
  };

  const onClickMicrosoftLogin = () => {
    setErrMessage(undefined);
    const auth = getAuth();
    const microsoftProvider = new OAuthProvider("microsoft.com");

    if (tenantId === undefined || tenantId === null) {
      console.error("Tenant ID is not set");
      return;
    }

    auth.tenantId = tenantId;
    logEvent(analytics, "clicked_microsoft_login_button");
    signInWithRedirect(auth, microsoftProvider);
  };

  if (tenantId === undefined) {
    return <LoadingScreen />;
  }
  if (tenantId === null) {
    return <NotFoundScreen />;
  }
  return (
    <ResponsiveCol className={"login-box"}>
      <Card
        withBorder
        filled
        sx={{
          padding: "40px",
          width: "auto",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Row sx={{ alignSelf: "start" }}>
          <Typography variant={"h1"} sx={{ marginBottom: "32px" }}>
            ログイン
          </Typography>
        </Row>
        <form onSubmit={onSubmit}>
          <Col>
            <ValidatedTextField
              type="email"
              label="メールアドレス"
              variant="outlined"
              size="medium"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              inputProps={{
                required: true,
              }}
              watchError={setEmailInputError}
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                width: "400px",
              }}
            />
            <ValidatedTextField
              label="パスワード"
              type="password"
              variant="outlined"
              size="medium"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              inputProps={{
                required: true,
              }}
              watchError={setPasswordInputError}
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                marginTop: "16px",
                width: "400px",
              }}
            />
            {!!errMessage ? (
              <Alert severity={"error"} sx={{ marginTop: "16px" }}>
                {errMessage}
              </Alert>
            ) : (
              <div />
            )}
            <LoadingButton
              type="submit"
              variant="contained"
              size={"large"}
              sx={{ alignSelf: "center" }}
              style={{ marginTop: "16px" }}
              buttonState={buttonState}
              disabled={hasError()}
            >
              ログイン
            </LoadingButton>
          </Col>
        </form>
        <LinkText sx={{ marginTop: "16px" }} href={"reset_password"} onClick={() => logEvent(analytics, "clicked_reset_password_link")}>
          パスワードが分からない場合
        </LinkText>
        <Col sx={{ width: "100%" }}>
          <Row sx={{ height: "16px" }} />
          <Divider />
          <SamlSsoLoginButton onLoginSuccess={onLoginSuccess} tenantId={tenantId} />
        </Col>
        {showDebugLogin ? (
          <Col sx={{ width: "100%" }}>
            <Row sx={{ height: "16px" }} />
            <Divider />
            <AuthLoginButton
              onClick={onClickGoogleLogin}
              style={{ marginTop: "24px", maxWidth: "100%" }}
              label="Googleでログイン"
              logo="Google"
            />
            <AuthLoginButton
              onClick={onClickMicrosoftLogin}
              style={{ marginTop: "24px", maxWidth: "100%" }}
              label="Microsoftでログイン"
              logo="Microsoft"
            />
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("user");
              }}
              variant="contained"
              buttonState={buttonState}
              size={"large"}
              sx={{ alignSelf: "center" }}
              style={{ marginTop: "24px" }}
            >
              Debug (User)
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("user2");
              }}
              variant="contained"
              buttonState={buttonState}
              size={"large"}
              sx={{ alignSelf: "center" }}
              style={{ marginTop: "24px" }}
            >
              Debug (User2)
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("admin");
              }}
              variant="contained"
              buttonState={buttonState}
              size={"large"}
              sx={{ alignSelf: "center" }}
              style={{ marginTop: "24px" }}
            >
              Debug (Admin)
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                logEvent(analytics, "clicked_debug_login_button");
                onClickDebugLogin("superAdmin");
              }}
              variant="contained"
              buttonState={buttonState}
              size={"large"}
              sx={{ alignSelf: "center" }}
              style={{ marginTop: "24px" }}
            >
              Debug (Super Admin)
            </LoadingButton>
          </Col>
        ) : (
          <></>
        )}
        <Typography style={{ marginTop: "30px", textAlign: "center", fontSize: "small", width: "100%" }}>
          利用規約に同意のうえログインしてください。
          <br />
          <LinkText href={"terms"}>利用規約</LinkText> |{" "}
          <LinkText href={"privacy"}>プライバシーポリシー</LinkText>
        </Typography>
      </Card>
    </ResponsiveCol>
  );
};

