import React, { Fragment, useRef, useState } from "react";
import { Col } from "../../../components/common/Col";
import { Alert, CircularProgress, SxProps, Typography } from "@mui/material";
import { StorageService } from "../../../models/services/storageService";
import { useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../../AppStates";
import { UserApiClient } from "../../../models/apiClients/userApiClient";
import { FileMetaDao } from "@chatforce/common/src/dao/firestoreDao";
import { ConfirmationDialog } from "../../../components/common/elements/ConfirmationDialog";
import { fileSizeChecker } from "../../../utils/fileSizeChecker";
import { MAX_FILE_SIZE_5GB } from "@chatforce/common/src/constants/constants";

interface Props {
  sx: SxProps;
  setFileMetas: React.Dispatch<React.SetStateAction<FileMetaDao[]>>;
  uploadedFileInfo: { id: string; fileName: string }[];
}

const FILE_UPLOAD_FOLDER = "uploads";

const ACCEPT_COMMON = ".pdf,.txt,.csv,.json,.jpg,.png,.mp4";
const ACCEPT_DOCS =
  ",.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const ACCEPT_EXCEL =
  ",.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const ACCEPT_PPTS =
  ",.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation";

export const FileUpload = (props: Props) => {
  const userClient = UserApiClient.getInstance();
  const storageService = StorageService.getInstance();

  const tenantUserState = useRecoilValueLoadable(currentTenantUserQuery);

  const inputRef = useRef<HTMLInputElement>(null);

  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingFilesCount, setUploadingFilesCount] = useState({
    totalFiles: 0,
    uploadingFileNum: 0,
  });
  const [showOverwriteAsk, setShowOverwriteAsk] = useState<File[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const tenantId = tenantUserState.contents.tenantId;

  const fileAcceptTypes = `${ACCEPT_COMMON}${ACCEPT_DOCS}${ACCEPT_EXCEL}${ACCEPT_PPTS}`;

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setDragging(false);
    const newFiles = [];

    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      newFiles.push(e.dataTransfer.files[i]);
    }

    checkFileNames(newFiles);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) return;

    const newFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }

    checkFileNames(newFiles);
  };

  const checkFileNames = (files: File[]) => {
    const duplicationExist = props.uploadedFileInfo.some((fileInfo) =>
      files.map((file) => file.name).includes(fileInfo.fileName),
    );

    if (duplicationExist) setShowOverwriteAsk(files);
    if (duplicationExist === false) uploadFiles(files);
  };

  const uploadFiles = async (files: File[]) => {
    if (files.some((item) => fileSizeChecker(MAX_FILE_SIZE_5GB, item.size))) {
      setErrorMessage("ファイルサイズは5GB以下にしてください。");
      return;
    }

    setUploading(true);
    setErrorMessage(undefined);

    try {
      for await (const file of files) {
        setUploadingFilesCount({
          totalFiles: files.length,
          uploadingFileNum: files.indexOf(file) + 1,
        });

        const fileWillOverwritten = props.uploadedFileInfo.find(
          (fileInfo) => file.name === fileInfo.fileName,
        );

        const payload: Pick<FileMetaDao, "id" | "fileName" | "size" | "type"> =
          {
            id: fileWillOverwritten?.id ?? "",
            fileName: file.name,
            type: file.type,
            size: file.size,
          };

        await storageService.uploadFile(
          tenantId,
          `${FILE_UPLOAD_FOLDER}/${file.name}`,
          file,
        );

        const addFileMetaRes = await userClient.upsertFileMeta(payload);

        const addingFileMeta: FileMetaDao = {
          ...payload,
          id: addFileMetaRes.id,
          createdAt: addFileMetaRes.createdAt,
        };

        props.setFileMetas((prev) => {
          const sameNamedFileMeta = prev.find(
            (item) => item.fileName === file.name,
          );

          if (sameNamedFileMeta)
            Object.assign(sameNamedFileMeta, addingFileMeta);
          else prev.push(addingFileMeta);

          return [...prev];
        });
      }

      setUploading(false);
      setErrorMessage(undefined);
    } catch (e) {
      console.error(e);
      setErrorMessage("ファイルのアップロードに失敗しました。");
    }
  };

  const handleClick = () => {
    if (uploading) return;
    inputRef.current?.click();
  };

  const handleConfirmOverwrite = async (files: File[]) => {
    setShowOverwriteAsk([]);
    await uploadFiles(files);
  };

  return (
    <>
      <Col
        sx={{
          border: "2px dotted black",
          borderRadius: "16px",
          backgroundColor: dragging ? "#3039df57" : "#E2E3E9",
          marginBottom: "32px",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          ...props.sx,
        }}
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <input
          ref={inputRef}
          type="file"
          accept={fileAcceptTypes}
          onChange={handleFileChange}
          onClick={(e: any) => {
            e.target.value = "";
          }}
          style={{ display: "none" }}
          multiple
        />

        {uploading ? (
          <>
            <CircularProgress size={"24px"} />
            <Typography
              variant="caption"
              sx={{ position: "absolute", bottom: "8px", right: "8px" }}
            >
              {`${uploadingFilesCount.uploadingFileNum} / ${uploadingFilesCount.totalFiles}`}
            </Typography>
          </>
        ) : (
          <Typography variant="h5"> 新規ファイルを追加</Typography>
        )}
      </Col>
      {!!errorMessage ? (
        <Alert severity={"error"} sx={{ marginTop: "16px" }}>
          {errorMessage}
        </Alert>
      ) : (
        <div />
      )}

      <ConfirmationDialog
        open={showOverwriteAsk.length > 0}
        title={"ファイルを保存"}
        body={"同じ名前のファイルを上書きしてもよいですか？"}
        buttonColor={"primary"}
        buttonText={"上書き"}
        onConfirm={() => handleConfirmOverwrite(showOverwriteAsk)}
        onClose={() => setShowOverwriteAsk([])}
      />
    </>
  );
};
