import { Alert, Link, Typography } from "@mui/material";
import { ComponentProps, FormEvent, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row } from "../../components/common/Row";
import { AnonymousApiClient } from "../../models/apiClients/anonymousApiClient";
import { useTenantId } from "../../models/hooks/hooks";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_PATTERN,
} from "@chatforce/common/src/constants/constants";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { Card } from "../../components/common/elements/Card";
import { configs } from "../../models/utils/Configs";
import { Col } from "../../components/common/Col";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { LoadingScreen } from "../Loading/LoadingScreen";
import { RedirectScreen } from "../../components/common/RedirectScreen";
import { SamlSsoLoginButton } from "../../components/common/SamlSsoLoginButton";
import { Divider } from "../../components/common/elements/Divider";

type LinkProps = ComponentProps<typeof Link>;
const LinkText = (props: LinkProps) => {
  return (
    <Link
      {...props}
      underline="none"
      sx={{
        ...props.sx,
        fontSize: "small",
        color: "#555555",
        padding: "1px",
      }}
    >
      {props.children}
    </Link>
  );
};

export const SignupScreen = () => {
  let [searchParams] = useSearchParams();
  const tenantId = useTenantId();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [password, setPassword] = useState<string>("");
  const invitationId = searchParams.get("invitationId");
  const [errMessage, setErrMessage] = useState<string | undefined>(undefined);
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [passwordInputError, setPasswordInputError] = useState<boolean>(false);
  const [isSignedUp, setIsSignedUp] = useState<boolean>(false);
  const analytics = getAnalytics();
  useEffect(() => {
    if (tenantId === null || tenantId === undefined || invitationId === null) {
      return;
    }
    const apiClient = AnonymousApiClient.getInstance();
    apiClient.getInvitation(tenantId, invitationId).then((invitation) => {
      setIsValid(invitation.isValid);
      setEmail(invitation.email);
    }).catch((error) => {
      console.error("Failed to get invitation", error);
      setIsValid(false);
    });
  }, [tenantId, invitationId]);
  const serviceName = configs.serviceName;
  if (isSignedUp) {
    return <RedirectScreen header={"登録完了"} message={"ユーザー登録が完了しました"} buttonText={"利用を開始する"} path={"/login"} />;
  }
  if (tenantId === null || invitationId === null) {
    console.error(
      `tenantId and invitationId shouldn't be null: ${tenantId} ${invitationId}`,
    );
    return <UnexpectedErrorScreen />;
  }
  if (tenantId === undefined || isValid === undefined) {
    console.log(tenantId, isValid);
    return <LoadingScreen />;
  }
  if (!isValid) {
    return (
      <ResponsiveCol>
        <Card withBorder filled>
          招待URLが無効です。管理者に依頼して再度発行してください。
        </Card>
      </ResponsiveCol>
    );
  }

  const hasError = (): boolean => {
    return passwordInputError;
  };
  const onLoginSuccess = () => {
    setErrMessage("");
    logEvent(analytics, "completed_signup");
    setIsSignedUp(true);
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    logEvent(analytics, "clicked_signup_button");
    if (tenantId === undefined) {
      console.error("Tenant ID is null");
      return;
    }
    setErrMessage(undefined);
    setButtonState("loading");
    const auth = getAuth();
    auth.tenantId = tenantId;
    if (email === undefined || password === undefined) {
      return;
    }
    const apiClient = AnonymousApiClient.getInstance();
    try {
      await apiClient.registerUserWithEmailAndPassword(
        email,
        password,
        tenantId,
        invitationId,
      );
      onLoginSuccess();
    } catch (error: any) {
      console.error(error);
      setErrMessage("登録に失敗しました。");
    }
    setButtonState("available");
  };

  return (
    <ResponsiveCol
      className={"login-box"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "auto",
      }}
    >
      <Card withBorder filled>
        <form onSubmit={onSubmit}>
          <Col
            sx={{
              minWidth: "600px",
              maxWidth: "600px",
              width: "100%",
              padding: "16px",
              paddingBottom: 0,
              alignSelf: "center",
              alignItems: "stretch",
            }}
          >
            <Row sx={{ alignSelf: "start" }}>
              <Typography variant={"h1"} sx={{ marginBottom: "32px" }}>
                ようこそ{serviceName}へ！
              </Typography>
            </Row>
            <Row
              sx={{
                paddingBottom: "40px",
              }}
            >
              <br />
              以下よりパスワードを登録してください。
            </Row>
            <Row
              sx={{
                borderRadius: "4px",
                width: "100%",
                fontWeight: "bold",
              }}
            >
              メールアドレス：{email}
            </Row>
            <ValidatedTextField
              label="パスワード"
              type="password"
              variant="outlined"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              // TODO: Set stronger password validation
              inputProps={{
                required: true,
                minLength: MIN_PASSWORD_LENGTH,
                maxLength: MAX_PASSWORD_LENGTH,
                pattern: PASSWORD_PATTERN,
              }}
              watchError={setPasswordInputError}
              style={{
                width: "100%",
                borderRadius: "4px",
                marginTop: "16px",
              }}
            />
            <Row sx={{ fontSize: "12px", marginTop: "16px" }}>
              パスワードは{MIN_PASSWORD_LENGTH}文字以上で、大文字・小文字・数字・記号を含む必要があります。
            </Row>
            <LoadingButton
              type="submit"
              variant="contained"
              size={"large"}
              buttonState={buttonState}
              disabled={hasError()}
              sx={{
                alignSelf: "center",
                marginTop: "24px",
              }}
            >
              登録
            </LoadingButton>
            <Typography style={{ marginTop: "30px", textAlign: "center", fontSize: "small", width: "100%" }}>
              利用規約に同意のうえログインしてください。
              <br />
              <LinkText href={"terms"}>利用規約</LinkText> |{" "}
              <LinkText href={"privacy"}>プライバシーポリシー</LinkText>
            </Typography>
            {!!errMessage ? (
              <Alert severity="error">{errMessage}</Alert>
            ) : (
              <div />
            )}
          </Col>
        </form>
        <Col sx={{ width: "100%" }}>
          <Row sx={{ height: "16px" }} />
          <Divider />
          <SamlSsoLoginButton onLoginSuccess={onLoginSuccess} tenantId={tenantId} />
        </Col>
      </Card>
    </ResponsiveCol>
  );
};
